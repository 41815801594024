import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 149.249 88.452"
    >
      <g id="c" data-name="Layer 1">
        <path
          d="m88.672,0v47.023C74.936,19.195,46.271,0,13.194,0H0v88.452h149.249V0h-60.577ZM8.648,79.804V8.648h4.546c40.168,0,73.108,31.541,75.355,71.156H8.648Zm131.952,0h-43.28V8.648h43.28v71.156Z"
          fill="#4dc9c8"
          strokeWidth="0"
        />
      </g>
    </svg>
  );
};

export default LogoIcon;
