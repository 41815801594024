import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
});
const LogoFull = ({
  classes: overrideClasses,
}: {
  classes?: Record<string, string>;
}) => {
  const classes = { ...useStyles(), ...overrideClasses };

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 424.085 89.498"
    >
      <g id="c" data-name="Layer 1">
        <g>
          <g>
            <path
              d="m179.798,1.046h10.302c11.976,0,18.564,6.797,18.564,18.302s-6.589,18.304-18.564,18.304h-10.302V1.046Zm10.25,31.585c8.629,0,13.23-4.915,13.23-13.283s-4.601-13.283-13.23-13.283h-4.864v26.565h4.864Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m217.61,0c2.039,0,3.399,1.359,3.399,3.347,0,2.091-1.359,3.452-3.399,3.452-2.092,0-3.399-1.361-3.399-3.452,0-1.987,1.307-3.347,3.399-3.347Zm-2.615,11.505h5.229v26.147h-5.229V11.505Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m226.136,28.709l5.229-.313c.262,3.242,2.667,5.073,7.426,5.073,3.556,0,5.804-1.464,5.804-3.713,0-1.987-2.144-3.034-7.792-3.66-7.112-.785-10.302-3.085-10.302-7.532,0-3.868,3.713-7.687,10.773-7.687,7.321,0,10.929,2.929,11.662,9.361l-5.124.366c-.262-3.818-2.406-5.178-7.164-5.178-3.19,0-5.073,1.151-5.073,2.98,0,1.987,1.359,2.772,7.164,3.503,7.74.994,10.877,2.825,10.877,7.845,0,4.498-3.503,8.263-11.557,8.263-7.792,0-11.557-3.4-11.923-9.309Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m259.973,16.368v21.284h-5.229v-19.401c0-4.393,2.406-6.799,6.798-6.799h10.564v4.916h-12.132Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m310.18,33.887v12.864h-5.229V11.505h5.229v3.555c1.673-2.824,4.341-4.183,8.053-4.183,7.531,0,12.237,5.229,12.185,13.597.052,8.366-4.654,13.596-12.185,13.596-3.713,0-6.38-1.359-8.053-4.183Zm7.426-.523c4.602,0,7.478-3.452,7.478-8.889s-2.876-8.838-7.478-8.838c-4.55,0-7.426,3.399-7.426,8.838s2.876,8.889,7.426,8.889Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m348.618,32.736v4.916h-4.81c-4.393,0-6.799-2.406-6.799-6.799v-14.434h-3.452v-4.915h3.452v-6.799h5.229v6.799h5.752v4.915h-5.752v16.316h6.38Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m357.249,0c2.039,0,3.399,1.359,3.399,3.347,0,2.091-1.359,3.452-3.399,3.452-2.092,0-3.399-1.361-3.399-3.452,0-1.987,1.307-3.347,3.399-3.347Zm-2.615,11.505h5.229v26.147h-5.229V11.505Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m364.519,11.505h5.752l6.066,19.558,6.066-19.558h5.752l-8.681,26.147h-6.276l-8.68-26.147Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m390.356,24.474c0-8.368,5.282-13.597,12.969-13.597,7.74,0,12.604,5.596,12.604,13.597,0,.626,0,1.516-.052,2.091h-20.081c.523,4.445,3.451,7.008,7.635,7.008,3.399,0,5.438-1.464,6.641-4.08l5.282.941c-1.883,4.706-6.327,7.635-11.923,7.635-8.106,0-13.073-4.863-13.073-13.596Zm20.29-1.831c-.471-4.393-3.294-7.322-7.322-7.322-4.079,0-6.798,2.824-7.478,7.322h14.799Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m179.798,84.061h6.432v-26.567h-6.432v-5.02h18.303v5.02h-6.432v26.567h6.432v5.02h-18.303v-5.02Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m217.765,67.325c-3.974,0-7.635,2.877-7.635,6.852v14.903h-5.229v-26.147h5.229v5.804c1.517-3.557,4.864-6.327,8.576-6.327,6.014,0,9.779,4.34,9.779,11.295v15.375h-5.229v-15.375c0-3.922-1.517-6.38-5.491-6.38Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m233.091,75.903c0-8.368,4.706-13.597,12.185-13.597,3.503,0,6.275,1.516,8.053,4.288v-15.165h5.229v37.652h-3.87l-.575-4.601h-.523c-1.778,3.137-4.811,5.02-8.838,5.02-7.008,0-11.662-5.229-11.662-13.596Zm12.812,8.889c4.655,0,7.426-3.295,7.426-8.889,0-5.544-2.771-8.838-7.426-8.838-4.602,0-7.478,3.399-7.478,8.838s2.876,8.889,7.478,8.889Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m264.313,77.627v-14.694h5.229v15.217c0,4.708,1.464,6.955,4.916,6.955,5.282,0,8.315-2.735,8.315-9.643v-12.529h5.229v26.147h-4.101l-.576-4.601h-.493c-1.883,3.45-5.028,5.02-9.003,5.02-6.536,0-9.517-3.713-9.517-11.871Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m274.983,26.199v-14.694h5.229v15.217c0,4.708,1.464,6.955,4.916,6.955,5.282,0,8.315-2.735,8.315-9.643v-12.529h5.229v26.147h-4.101l-.576-4.601h-.493c-1.883,3.45-5.028,5.02-9.003,5.02-6.536,0-9.517-3.713-9.517-11.871Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m293.13,80.138l5.229-.313c.262,3.242,2.667,5.073,7.426,5.073,3.556,0,5.804-1.464,5.804-3.713,0-1.987-2.144-3.034-7.792-3.66-7.112-.785-10.302-3.085-10.302-7.532,0-3.868,3.713-7.687,10.773-7.687,7.321,0,10.929,2.929,11.662,9.361l-5.124.366c-.262-3.818-2.406-5.178-7.164-5.178-3.19,0-5.073,1.151-5.073,2.98,0,1.987,1.359,2.772,7.164,3.503,7.74.994,10.877,2.825,10.877,7.845,0,4.498-3.503,8.263-11.557,8.263-7.792,0-11.557-3.4-11.923-9.309Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m334.183,84.164v4.916h-4.81c-4.393,0-6.799-2.406-6.799-6.799v-14.434h-3.452v-4.915h3.452v-6.799h5.229v6.799h5.752v4.915h-5.752v16.316h6.38Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m344.643,67.796v21.284h-5.229v-19.401c0-4.393,2.406-6.799,6.798-6.799h10.564v4.916h-12.132Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m363.053,51.428c2.039,0,3.399,1.359,3.399,3.347,0,2.091-1.359,3.452-3.399,3.452-2.092,0-3.399-1.361-3.399-3.452,0-1.987,1.307-3.347,3.399-3.347Zm-2.615,11.505h5.229v26.147h-5.229v-26.147Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m371.579,75.903c0-8.368,5.282-13.597,12.969-13.597,7.74,0,12.604,5.596,12.604,13.597,0,.626,0,1.516-.052,2.091h-20.081c.523,4.445,3.452,7.008,7.635,7.008,3.399,0,5.438-1.464,6.641-4.08l5.282.941c-1.883,4.706-6.327,7.635-11.923,7.635-8.106,0-13.073-4.863-13.073-13.596Zm20.29-1.831c-.471-4.393-3.294-7.322-7.322-7.322-4.078,0-6.798,2.824-7.478,7.322h14.799Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
            <path
              d="m400.605,80.138l5.229-.313c.262,3.242,2.667,5.073,7.426,5.073,3.556,0,5.804-1.464,5.804-3.713,0-1.987-2.144-3.034-7.792-3.66-7.112-.785-10.302-3.085-10.302-7.532,0-3.868,3.713-7.687,10.773-7.687,7.321,0,10.929,2.929,11.662,9.361l-5.124.366c-.262-3.818-2.406-5.178-7.164-5.178-3.19,0-5.073,1.151-5.073,2.98,0,1.987,1.359,2.772,7.164,3.503,7.74.994,10.877,2.825,10.877,7.845,0,4.498-3.503,8.263-11.557,8.263-7.792,0-11.557-3.4-11.923-9.309Z"
              fill="#4dc9c8"
              strokeWidth="0"
            />
          </g>
          <path
            d="m88.672,1.046v47.023C74.936,20.241,46.271,1.046,13.194,1.046H0v88.452h149.249V1.046h-60.577ZM8.648,80.85V9.695h4.546c40.168,0,73.108,31.541,75.355,71.155H8.648Zm131.952,0h-43.28V9.695h43.28v71.155Z"
            fill="#4dc9c8"
            strokeWidth="0"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoFull;
