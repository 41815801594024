import {
  HomePageToolkit,
  HomePageCompanyLogo,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import { Content, Page, InfoCard } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import LogoFull from '../Root/LogoFull';
import {
  Si1password,
  SiConfluence,
  SiGithub,
  SiGmail,
  SiGooglecalendar,
  SiGooglecloud,
  SiJira,
  SiMiro,
  SiSlack,
  SiStorybook,
} from '@icons-pack/react-simple-icons';
import CategoryIcon from '@material-ui/icons/Category';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PeopleIcon from '@material-ui/icons/People';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const { svg, container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<LogoFull classes={{ svg }} />}
            />
            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  tools={[
                    {
                      url: '/catalog',
                      label: 'Catalog',
                      icon: <CategoryIcon />,
                    },
                    {
                      url: '/docs',
                      label: 'TechDocs',
                      icon: <LibraryBooksIcon />,
                    },
                    {
                      url: 'https://storybook.disruptive-industries.io',
                      label: 'Storybook',
                      icon: <SiStorybook />,
                    },
                    {
                      url: 'https://github.com/orgs/Disruptive-Industries/repositories',
                      label: 'GitHub',
                      icon: <SiGithub />,
                    },
                    {
                      url: 'https://disruptive-industries.atlassian.net/wiki/spaces/SD/overview?homepageId=163941',
                      label: 'Confluence',
                      icon: <SiConfluence />,
                    },
                    {
                      url: 'https://disruptive-industries.atlassian.net/jira',
                      label: 'Jira',
                      icon: <SiJira />,
                    },
                    {
                      url: 'https://mail.google.com',
                      label: 'Gmail',
                      icon: <SiGmail />,
                    },
                    {
                      url: 'https://calendar.google.com',
                      label: 'Calendar',
                      icon: <SiGooglecalendar />,
                    },
                    {
                      url: 'https://disruptive-industries.charliehr.com',
                      label: 'CharlieHR',
                      icon: <PeopleIcon />,
                    },
                    {
                      url: 'https://disruptiveind-3cz3529.slack.com',
                      label: 'Slack',
                      icon: <SiSlack />,
                    },
                    {
                      url: 'https://console.cloud.google.com',
                      label: 'GCP',
                      icon: <SiGooglecloud />,
                    },
                    {
                      url: 'https://miro.com/app/dashboard',
                      label: 'Miro',
                      icon: <SiMiro />,
                    },
                    {
                      url: 'https://app.1password.com/home',
                      label: '1Password',
                      icon: <Si1password />,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InfoCard title="Composable Section">
                  {/* placeholder for content */}
                  <div style={{ height: 370 }} />
                </InfoCard>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
